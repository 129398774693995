import { APP_API_URL } from 'constants/settings';
import { WeeklyScheduleRequestPayload } from 'store/flexibleSchedule/types';

export const teachersListAPI = (branch: number) => `/api/school_system/branch/${branch}/teacher/`;

export const studentsListAPI = (branch: number) => `/api/school_system/branch/${branch}/student/`;

export const exportTeachersList = (branch: number) =>
  `/api/school_system/branch/${branch}/teacher/download/`;

export const exportStudentsList = (branch: number) =>
  `/api/school_system/branch/${branch}/student/download/`;

export const teacherDetailAPI = (branch: number, teacherId: number) =>
  `/api/school_system/branch/${branch}/teacher/${teacherId}/`;

export const currentSemesterWeekAPI = () => `/api/semester/current-semester-details/`;

export const getSemesterWeeksUrl = (semesterId: number) => `/api/semester/${semesterId}/weeks/`;

export const enrollStudentAPI = (plan: number) =>
  `/api/service_based_subscription/plans/${plan}/enroll/`;

export const draftedSubscriptionAPI = () => `api/service_based_subscription/user_subject_services/`;

export const subscriptionPlansAPI = () => '/api/service_based_subscription/plans/';

export const subscriptionsSubjectsServicesAPI = (planId: number, gradeId: number, userId: number) =>
  `/api/service_based_subscription/${planId}/user/${userId}/grade/${gradeId}/subject-services/`;

export const validateCouponCodeAPI = `/api/service_based_subscription/coupon/validate`;

export const makePaymentAPI = () => '/api/subscriptions/make-payment/';

export const createSlotAPI = (classroomId: number, branchId: number) =>
  `/api/alw/timetable/branches/${branchId}/classrooms/${classroomId}/slots/`;

export const updateSlotAPI = (classroomId: number, branchId: number, slotId: number) =>
  `/api/alw/timetable/branches/${branchId}/classrooms/${classroomId}/slots/${slotId}/`;

export const checkConflictsAPI = (classroomId: number, branchId: number) =>
  `/api/alw/timetable/branches/${branchId}/classrooms/${classroomId}/check-conflict/`;

export const getSlotsAPI = (classroomId: number, branchId: number) =>
  `/api/alw/timetable/branches/${branchId}/classrooms/${classroomId}/`;

export const getPublishStatusAPI = (classroomId: number, branchId: number) =>
  `/api/alw/timetable/branches/${branchId}/classrooms/${classroomId}/publish-status/`;

export const publishEventsAPI = (branchId: number, classroomId: number) =>
  `/api/alw/timetable/branches/${branchId}/classrooms/${classroomId}/publish/`;

export const branchStatsAPI = (branch: number) => `/api/school_system/branches/${branch}/stats/`;

export const studentDetailAPI = (branch: number, studentId: number) =>
  `/api/school_system/branch/${branch}/student/${studentId}/`;

export const archiveTeacherAPI = (branch: number, teacherId: number) =>
  `/api/school_system/branch/${branch}/archive-teacher/${teacherId}/`;

export const markAttendanceAPI = (id: number) =>
  `/api/mentora/calendar/events/${id}/update-student-attendance`;

export const commentAPI = (branchId: number, eventId: number) =>
  `/api/alw/comment/branch/${branchId}/event/${eventId}/`;

export const scheduleFilterAPI = (branchId: number) =>
  `/api/school_system/events/branch/${branchId}/form-options/`;

export const archiveStudentAPI = (branch: number, studentId: number) =>
  `/api/school_system/branch/${branch}/archive-student/${studentId}/`;

export const getEventDetailAPI = (id: number) => `/api/mentora/calendar/event/${id}/`;

export const getBranchEventsAPI = () => `/api/mentora/calendar/events/`;

export const addStudentAPI = (branch: number) => `/api/school_system/branch/${branch}/student/`;

export const studentOptionsAPI = (branch: number) =>
  `/api/school_system/student/branch/${branch}/form-options/`;

export const addTeacherAPI = (branch: number) => `/api/school_system/branch/${branch}/teacher/`;

export const teacherOptionsAPI = (branch: number) =>
  `/api/school_system/teacher/branch/${branch}/form-options/`;

export const updateTeacherAPI = (branch: number, teacherId: number) =>
  `/api/school_system/branch/${branch}/teacher/${teacherId}/`;

export const updateStudentAPI = (branch: number, studentId: number) =>
  `/api/school_system/branch/${branch}/student/${studentId}/`;

export const b2bTeacherStatsApi = (teacherID: number) =>
  `/api/school_system/teachers/${teacherID}/stats/`;

export const b2bTeacherStudentsApi = (teacherID: number) =>
  `/api/school_system/teachers/${teacherID}/students/`;

export const b2bTeacherSessionsApi = (teacherID: number) =>
  `/api/school_system/teachers/${teacherID}/live-sessions/`;

export const getSpamWordsListApi = () => '/api/alw_chat/filtered_words/';

export const generateChatWarningApi = () => '/api/alw_chat/chat_warnings/';

export const b2bTeacherClassRoomOptionsApi = (teacherID: number) =>
  `/api/school_system/teachers/${teacherID}/classrooms/`;

export const b2bTeacherClassRoomOptionsWithoutPaginationApi = (
  teacherID: number,
  studentID: number,
) => `/api/school_system/teachers/${teacherID}/classrooms/${studentID}/students/`;

export const b2bTeachersBulkCreate = (branchID: number) =>
  `/api/school_system/branches/${branchID}/bulk-upload/teacher/`;

export const b2bStudentsBulkCreate = (branchID: number) =>
  `/api/school_system/branches/${branchID}/bulk-upload/student/`;
export const b2bTeacherStudentProfileAPI = (teacherID: number, studentID: number) =>
  `/api/school_system/teachers/${teacherID}/students/${studentID}/`;

export const b2bTeacherStudentAttachmentAPI = (studentID: number) =>
  `/api/school_system/students/${studentID}/`;

export const b2bTeacherUpdatePassword = (branchId: number, teacherId: number) =>
  `/api/school_system/branch/${branchId}/teacher/${teacherId}/password_change/`;

export const b2bStudentUpdatePassword = (branchId: number, studentId: number) =>
  `/api/school_system/branch/${branchId}/student/${studentId}/password_change/`;
export const getGradesClassRoomsAPI = (branchID: number) =>
  `/api/school_system/branches/${branchID}/grades-classrooms/`;

export const getSubjectsTeachersAPI = (classId: number) =>
  `/api/school_system/classes/${classId}/subjects/`;

export const assignGradeToTeacherAPI = (branchID: number) =>
  `/api/school_system/branches/${branchID}/assign-teacher/`;

export const getTeachersDetailsAPI = (branchID: number) =>
  `/api/school_system/branch/${branchID}/teacher-expertise/`;

export const getQuestionBankSubjectsAPI = (search?: string) =>
  `/api/question_banks/${search ? `?q=${search}` : ''}`;

export const getLessonLearningObjectsAPI = (subjectId: string, lessonKey: string) =>
  `/api/question_banks/${subjectId}/lessons/${lessonKey}/learning_objectives/`;

export const getLessonUnitsAPI = (unitKey: string, search?: string) =>
  `/api/question_banks/${unitKey}/${search ? `?q=${search}` : ''}`;

export const getLessonResultsAPI = (subjectId: string, lessonKey: string) =>
  `/api/question_banks/${subjectId}/lessons/${lessonKey}/score_board/`;

export const getBlockStatusAPI = (groupId: number) =>
  `/api/alw_chat/user_block_status?group_id=${groupId}`;

export const getSampleQuestionsAPI = (
  subjectId: string,
  learningObjectId: number,
  pageNumber: number,
) =>
  `api/question_banks/${subjectId}/student/sample-questions/?page_size=1&page=${pageNumber}&lesson_learning_objective=${learningObjectId}`;

export const getLearningObjectivesProblemAPI = (
  subjectId: string,
  learningObjectId: number,
  lessonKey: string,
  pageSize?: number,
) =>
  `/api/question_banks/${subjectId}/lessons/${lessonKey}/learning_objectives/${learningObjectId}/problems/?page_size=${pageSize}`;

export const addLiveSessionAttachments = (eventId: number) =>
  `/api/mentora/calendar/events/${eventId}/attachments/`;

const getAttachmentUrl = '/api/mentora/calendar/event-attachments/';
export const getTeacherEventAttachmentsAPI = (queryString?: string) =>
  queryString ? `${getAttachmentUrl}${queryString}/` : getAttachmentUrl;

export const declineEventRequestAPI = (eventId: number) =>
  `/api/mentora/calendar/events/${eventId}/reject`;

const gradesUrl = '/api/mentora/taxonomy/grades/';
export const getGradesAPI = (queryString?: string) =>
  queryString ? `${gradesUrl}${queryString}` : gradesUrl;

export const getGradeStudentsAPI = (gradeId: number) => `${gradesUrl}${gradeId}/students`;

export const getTeacherGradesAPI = (search?: string) =>
  `/api/reports-manager/grades/${search ? `?search=${search}` : ''}`;

export const getGradeDetailAPI = (gradeId: number, isALWTeacher?: boolean, teacherId?: number) =>
  isALWTeacher
    ? `/api/reports-manager/grades/${gradeId}/students/`
    : `/api/school_system/teachers/${teacherId}/students/`;

export const getStudentReportsAPI = (
  studentId: number,
  startDate: string,
  endDate: string,
  semester: number,
) =>
  `/api/reports-manager/students/${studentId}/report/?start_date=${startDate}&end_date=${endDate}&semester=${semester}`;

export const getStatsScalesAPI = () => `/api/reports-manager/performance-scale/`;

export const getQuestionListAPI = (courseId: string, lessonId: string) =>
  `/api/question_banks/teacher/${courseId}/lessons/${lessonId}/questions/`;

export const updateChildDetailsAPI = (studentId: number) => `/api/parent/children/${studentId}`;

export const getSelfStudentReportAPI = (startDate: string, endDate: string, semester?: number) =>
  `/api/reports-manager/self/report/?start_date=${startDate}&end_date=${endDate}&semester=${semester}`;

export const getChildReportAPI = (
  studentId: number,
  startDate: string,
  endDate: string,
  semester: number,
) =>
  `/api/reports-manager/children/${studentId}/report/?start_date=${startDate}&end_date=${endDate}&semester=${semester}`;

export const getB2BTeacherGradesAPI = (search?: string, id?: number) =>
  `/api/school_system/teachers/${id}/grades-classrooms/${search ? `?search=${search}` : ''}`;

export const getChildrenReportsAPI = () => `/api/reports-manager/children-list/`;

export const getResendPasswordV2API = () => '/api/mentora/auth/reset-password-v2/';

export const getGenerateOTPAPI = () => '/api/otp/generate-signin-otp/';

export const getVerifyOTPAPI = () => '/api/otp/verify-signin-otp';

export const getForgetPasswordV2API = () => '/api/mentora/auth/resend-credentials/';

export const getTeacherClassesAPI = (search?: string) =>
  `/api/question_banks/teacher/grade_wise_question_banks/${search ? `?q=${search}` : ''}`;

export const getTeacherSubjectsAPI = (gradeId: string | undefined) =>
  `/api/question_banks/teacher/${gradeId ? `?grade_id=${gradeId}` : ''}`;

export const getTeacherUnitsAPI = (subjectKey: string) =>
  `/api/question_banks/teacher/${subjectKey}/units/`;

export const getRASALanguageAPI = (baseUrl: string, email: string) =>
  `${baseUrl}conversations/${email}/tracker`;

export const getRASAResetAPI = (baseUrl: string, email: string, token?: string) =>
  `${baseUrl}conversations/${email}/tracker/events?token=${token}`;

export const getTeacherLessonsAPI = (subjectKey: string, unitKey: string | number | undefined) =>
  `/api/question_banks/teacher/${subjectKey}/units/${unitKey}/lessons/`;

export const getAnswerDetailOptionsAPI = () => '/api/question_banks/problem_types/';

export const uploadQuestionImageAPI = () => '/api/question_banks/upload-image/';

export const avatarAPI = () => '/api/digital-tutor/avatar/';

export const createDeleteQuestionAPI = (questionId?: number) =>
  `api/question_banks/questions${questionId ? `/${questionId}/` : '/'}`;
export const rateLiveEvent = (eventId: number) => `/api/live_session_feedback/${eventId}/rate/`;

export const liveEventStudentQuestions = (eventId: number) =>
  `/api/live_session_feedback/${eventId}/questions/`;

export const liveEventTeacherQuestions = (value: string) =>
  `/api/live_session_feedback/teacher_sessions/?query=${value}`;

export const liveEventAnswers = (eventId: number, questionId: number | undefined) =>
  `/api/live_session_feedback/${eventId}/questions/${questionId}/answers/`;

export const deleteLiveQuestion = (eventId: number, questionId: number | undefined) =>
  `/api/live_session_feedback/${eventId}/questions/${questionId}/`;

export const getAttemptedQuestions = (
  subjectId: number,
  page: number,
  lessonLearningObjective: number,
) =>
  `api/question_banks/${subjectId}/attempts/?page_size=1&page=${page}&lesson_learning_objective=${lessonLearningObjective}`;

export const getLearningObjectiveQuestions = (
  subjectId: string,
  lessonLearningObjectiveId: number,
  questionGroupId?: number,
) =>
  `api/question_banks/${subjectId}/student/question/?lesson_learning_objective=${lessonLearningObjectiveId}${
    questionGroupId !== undefined && questionGroupId !== null
      ? `&question_group_id=${questionGroupId}`
      : ''
  }`;

export const postSubmitQuestionsAnswerApi = (subjectId: string, questionGroupId: number) =>
  `api/question_banks/${subjectId}/question-groups/${questionGroupId}/submit-answers/`;

export const deleteAttachment = (eventId: number, attachmentId: number | undefined) =>
  `/api/mentora/calendar/events/${eventId}/delete-attachment/${attachmentId}/`;

export const fetchTeacherAssignments = (teacherId: number) =>
  `api/alw/content_library/teachers/${teacherId}/assignments/`;

export const fetchTeacherAssignmentDetail = (teacherId: number, assignmentId: number) =>
  `api/alw/content_library/teachers/${teacherId}/assignments/${assignmentId}/`;

export const updateStudentAssignmentScoreApi = (teacherId: number, studentAssignmentId: number) =>
  `api/alw/content_library/teachers/${teacherId}/student-assignments/${studentAssignmentId}/`;

export const addNewAssignments = () => `api/alw/content_library/assign/`;

export const fetchStudentSubjects = () => `api/alw/content_library/get-student-subjects/`;

export const fetchStudentAssignments = (subjectId: string) =>
  `api/alw/content_library/subject/${subjectId}/`;

export const submitStudentAssignmentApi = (studentAssignmentId: number) =>
  `api/alw/content_library/assignments/${studentAssignmentId}/mark-as-complete/`;

export const teacherAssignmentGroupsApi = (classId: number, subjectId: string) =>
  `api/alw/content_library/teacher-groups/${classId}/subject/${subjectId}/`;

export const teacherAssignmentResubmissionApi = (assignmentId: number, groupId: number) =>
  `/api/alw/content_library/assignment/${assignmentId}/group/${groupId}/`;

export const getSubjectGradesAPI = () => '/api/question_banks/teacher/grades/';

export const getBranchClassroomsAPI = (branchId = 1, pageSize = 200) =>
  `/api/school_system/branches/${branchId}/classrooms/?page_size=${pageSize}`;

export const getBranchClassroomDetailsAPI = (classroomId: number) =>
  `/api/reports-manager/classes/${classroomId}/report/`;

export const getPerformanceStatsAPI = (classId: number) =>
  `/api/reports-manager/classes/${classId}/performance-stats/?page_size=200`;

export const getPerformanceStatsStudentsAPI = (classId: number, subjectId: string) =>
  `/api/reports-manager/classes/${classId}/subjects/${subjectId}/students/`;

export const sendTeacherEmailAPI = () => `/api/teacher-recommendations/send-mail/`;

export const getTeacherExamsAPI = () => '/api/alw/exams/teacher/';
export const getTeacherExamDetailAPI = (examId: number) => `/api/alw/exams/${examId}/teacher/`;
export const createExamQuestionAPI = (examId: number) =>
  `/api/alw/exams/${examId}/teacher/create-problem/`;
export const createNewExamAPI = () => `/api/alw/exams/create/`;

export const getExamsLessonsAPI = (courseId: number) => `/api/alw/exams/${courseId}/units/lessons/`;

export const getStudentExamsListAPI = () => '/api/alw/exams/student-exams/';

export const getExamsClassesAPI = () => '/api/alw/exams/teacher-classes/';

export const deleteSlotAPI = (branch: number, classroom: number, slotId: number) =>
  `api/alw/timetable/branches/${branch}/classrooms/${classroom}/slots/${slotId}/`;

export const resetSlotAPI = (branch: number, classroom: number) =>
  `api/alw/timetable/branches/${branch}/classrooms/${classroom}/reset/`;

export const getExamsSubjectsAPI = (gradeId: string) =>
  `/api/alw/exams/teacher-classes/${gradeId}/subjects/`;

export const publishExamAPI = (examId: string) => `/api/alw/exams/${examId}/update_status/`;

export const fetchLearningObjectivesAPI = () => '/api/lessons-learning-objectives/';

export const fetchStudentResultsAPI = (examId: string) =>
  `/api/alw/exams/${examId}/student-results/`;

export const getStudentExamApi = (examId: number) => `/api/alw/exams/${examId}/problems/`;

export const exitStudentExamAPI = () => `/api/alw/exams/student-exams/`;

export const studentCurrentExamAPI = (examId: string) => `/api/alw/exams/student-exams/${examId}/`;

export const teacherExamProblemsAPI = (examId: string) => `/api/alw/exams/${examId}/exam-problems/`;

export const examProblemAPI = (problemKey: string) => `/api/alw/exams/${problemKey}/problem/`;

export const getClassroomRecommendationsAPI = (classId: number) =>
  `/api/teacher-recommendations/classes/${classId}/recommendations/`;

export const getLiveClassRecommendationsListAPI = (classId: number) =>
  `/api/teacher-recommendations/classroom-subject/${classId}/recommendations/`;

export const getLiveSessionData = (id: number) => `/api/teacher-recommendations/${id}/`;

export const learningObjectiveSmartScoreAPI = (
  subjectId: string,
  lessonKey: string,
  learningObjectId: number,
) =>
  `/api/question_banks/${subjectId}/lessons/${lessonKey}/learning_objectives/${learningObjectId}/smart_score/`;

export const updateExpectedAnswersAPI = (learningObjecivetId: number) =>
  `/api/learning-objectives/${learningObjecivetId}/`;

export const getStatsScalesByGradeAPI = (gradeId: number) =>
  `/api/reports-manager/performance-scale/${gradeId}/`;

export const flexibleScheduleClassesAPI = (teacherId: string) =>
  `/api/flexible_schedule/classroom_wise_subjects/${teacherId}/`;

export const flexibleScheduleBMClassesAPI = (branchId: number, teacherId: string) =>
  `/api/flexible_schedule/branch/${branchId}/classroom_wise_subjects/${teacherId}/`;

export const teachersScheduleAPI = (branchId: string) =>
  `/api/flexible_schedule/branch/${branchId}/teacher_classroom/`;

export const getTeacherWeeklyScheduleAPI = (payload: WeeklyScheduleRequestPayload) => {
  const { teacherId, subjectId, semesterId, classId } = payload;
  return `/api/flexible_schedule/teacher/${teacherId}/subject/${subjectId}/semester/${semesterId}/classroom/${classId}/`;
};

export const getAdminWeeklyScheduleAPI = (payload: WeeklyScheduleRequestPayload) => {
  const { branchId, teacherId, subjectId, semesterId, classId } = payload;
  return `/api/flexible_schedule/branch/${branchId}/teacher/${teacherId}/subject/${subjectId}/semester/${semesterId}/classroom/${classId}/`;
};
export const tourManagementAPI = (page: string) => `/api/tours/pages/${page}`;
export const setWatchedTourAPI = () => `/api/tours/watch/`;
export const semesterCompletionProgressAPI = (subjectId: string) =>
  `/api/mentora/progress/semester/completion-progress/${subjectId}/`;
export const semesterCourseProgressAPI = (courseId: string) =>
  `/api/mentora/progress/semester/course-score/${courseId}/`;
export const getReinforcementEventsListAPI = () => `/api/alw/exams/reinforcement-events-list/`;

export const getPromotionVideosCardsAPI = () => `/api/marketing/promotion_page/`;

export const getPromotionNewsUpdatesAPI = () => `/api/marketing/news_and_updates/`;

// APIs of Summer school
// export const summerSchoolCheckoutAPI = ()=> `summer_school_checkout/`;
// export const subscriptionRootAPI = ()=> `/api/subscriptions/`;
// export const summerSchoolRegistrationAPI = ()=> `api/mentora/auth/register-summer-school/`;
// export const alwSummerSchoolRegistrationAPI = ()=> `api/mentora/auth/alw-user-summer-school-registration/`;
// export const summerSchoolLevelsAPI = ()=> `api/summer_school/levels/`;
// export const summerStudentPaymentAPI = ()=> `api/subscriptions/user_summer_school_subscriptions`;
// export const summerSubjectsAPI = ()=> `api/summer_school/subjects/`;
// export const summerSchoolSubscriptionsAPI = ()=> `api/subscriptions/summer_school_subscriptions`;
export const getAssignedSubjectsAPI = () => '/api/mentora/subjects/assigned/';

export const getLiveClassRecordingsAPI = () =>
  '/api/alw/content_library/get-live-class-recordings/';

export const getContentLibraryFiltersAPI = () => '/api/alw/content_library/get_filters_data/';

export const getContentLibraryContentAPI = () => '/api/alw/content_library/search/';

export const getContentLibrarySubjects = () => '/api/alw/content_library/subjects/';
export const updateMappingExamsDetail = (teacherId: string, subjectId: string, exam: string) =>
  `/api/b2b-grading-system/teachers/${teacherId}/subjects/${subjectId}/exams/${exam}/`;

export const getMappingExamsDetail = (teacherId: string, subjectId: string, semester: string) =>
  `/api/b2b-grading-system/teachers/${teacherId}/subjects/${subjectId}/exams/?semester_id=${semester}`;

export const getMentorGrades = () => '/api/alw/content_library/mentor-grades/';

export const getStudentGrades = (grade: string) =>
  `/api/alw/content_library/mentor-grades/${grade}/students/`;

export const selfAssignLesson = () => '/api/alw/content_library/self-assign/';

export const assignLessonAPI = () => '/api/alw/content_library/assign/';

export const assignLiveClassContentAPI = () =>
  '/api/alw/content_library/live-class-recording-assignment/';

export const markContentCompleteApi = (contentId: string) =>
  `/api/alw/content_library/assignments/${contentId}/mark-as-complete/`;

export const sendPaymentDetail = (route: string) => `/api/subscriptions/${route}`;

export const getWeeklyPlanAPI = () => `/api/mentora/enrollment/studied-weeks/`;

export const getWeekLessonsToDoAPI = (userId: number, weekId: number) =>
  `/api/mentora/enrollment/users/${userId}/weeks/${weekId}/weekly-lessons/`;

export const getWeekLiveSessionsAPI = (userId: number, weekId: number) =>
  `/api/mentora/enrollment/users/${userId}/weeks/${weekId}/weekly-events/`;

export const getChildTeachersAPI = (childId: number) => `/api/parent/children/${childId}/teachers/`;

export const getTeacherQuestionsAPI = (childId: string, teacherId: string) =>
  `/api/parent-teacher-conversation/students/${childId}/teachers/${teacherId}/questions/`;

export const deleteTeacherQuestionAPI = (childId: string, teacherId: string, questionId: number) =>
  `/api/parent-teacher-conversation/students/${childId}/teachers/${teacherId}/questions/${questionId}/`;

export const getChildTeacherDetailAPI = (childId: string, teacherId: string) =>
  `/api/parent/children/${childId}/teachers/${teacherId}/`;

export const postQuestionAPI = (studentId: string, teacherId: string) =>
  `/api/parent-teacher-conversation/students/${studentId}/teachers/${teacherId}/questions/`;

export const getParentQuestionsAPI = () => `/api/parent-teacher-conversation/students/`;

export const getChildDetailAPI = (childId: string) =>
  `/api/parent-teacher-conversation/students/${childId}/`;

export const postUpdateAnswerAPI = (childId: string, teacherId: string, questionId: number) =>
  `${deleteTeacherQuestionAPI(childId, teacherId, questionId)}answers/`;

export const getSignupConfig = () => '/api/get-signup-config/';

export const saveAutoDeductAPI = (userId: number) =>
  `/api/subscriptions/auto-renew-subscription/${userId}/`;

export const getLessonDetailsAPI = () => `/api/tv-like-dashboard/unit-lessons-detail/`;

export const getAddCardAPI = () => '/api/subscriptions/user-card-information/';

export const getRecommendationSubjectsAPI = () => '/api/student-recommendations/subjects/';

export const getSubjectRecommendationsByIdAPI = (subjectId: string) =>
  `/api/student-recommendations/subjects/${subjectId}/`;

export const regeneratePasswordAPI = (branch: number) =>
  `/api/school_system/branch/${branch}/regenerate-password/`;

export const generateOTPUrl = () => '/api/otp/generate-update-otp';

export const verifyOTPUrl = () => '/api/otp/verify-update-otp';

export const fetchNativeEventGradeSubjectsAPI = `/api/mentora/lessons/grade-subjects/`;

export const fetchLessonEventsAPI = (lessonKey: string) =>
  `/api/question-pools/${lessonKey}/native-events/`;

export const startQuestionPoolsAttemptUrl = (eventId: string) =>
  `/api/question-pools/${eventId}/start-pool-attempt/`;

export const submitQuestionGroupsAnswersUrl = (eventId: string, groupId: number) =>
  `/api/question-pools/${eventId}/question-groups/${groupId}/submit-answers/`;

export const getQuestionPoolsDetailsUrl = (eventId: string) =>
  `/api/question-pools/${eventId}/pool-detail/`;

export const fetchNativeEventQuestions = (eventKey: string) =>
  `/api/question-pools/${eventKey}/question-groups/`;

export const fetchIssueAllCertificateStatus = (branch: number, grade: number, semester: number) =>
  `/api/b2b-grading-system/branch/${branch}/grade/${grade}/semester/${semester}/issue-certificates/`;

export const createLearningObjectiveAPI = () => '/api/create-and-link-learning-objective/';

export const deleteMainQuestionAPI = (eventKey: string, groupId: number) =>
  `/api/question-pools/${eventKey}/question-groups/${groupId}/`;

export const submitStudentAttemptsAPI = (eventId: string, attemptId: number) =>
  `/api/question-pools/${eventId}/student-attempts/${attemptId}/`;

export const deleteSubQuestionAPI = (questionId: number) =>
  `/api/mentora/lessons/questions/${questionId}/`;

export const fetchLearningObjectiveAPI = () => `/api/learning-objectives/`;

export const createQuestionsAPI = (eventKey: string) =>
  `/api/question-pools/${eventKey}/questions/`;

export const uploadContentAPI = () => `/api/mentora/lessons/upload-content/`;

export const deleteContentAPI = () => `/api/mentora/lessons/delete-content/`;

export const discardChangesAPI = (eventKey: string) =>
  `/api/question-pools/${eventKey}/update-pool-status/`;

export const editSubQuestionAPI = (eventId: string, groupId: number) =>
  `/api/question-pools/${eventId}/question-groups/${groupId}/update-questions/`;

export const getRegistrationAPI = () => 'api/mentora/auth/v2/register/';

export const getChildrenRegistrationAPI = () => 'api/mentora/auth/v2/register-children/';

export const getUserEnrollmentAPI = () => '/api/mentora/auth/v2/enroll-user-to-course/';

export const getUserSemesterCoursesAPI = () => '/api/mentora/auth/v2/user-class-courses/';

export const orientationVideosAPI = () => '/api/tours/orientation-videos/';

export const orientationQuicKDemoAPI = () => '/api/tours/orientation-videos/quick-demo/';

export const getStudentDashboardDataUrl = () => '/api/v2/tv-like-dashboard/course-tiles/';

export const getOverdueLessonsUrl = () => 'api/v2/tv-like-dashboard/overdue-lessons/';

export const getUnitsBySubject = (subjectId: string) =>
  `/api/v2/tv-like-dashboard/${subjectId}/units/`;

export const getAdditionalLessonsUrl = () => '/api/alw/content_library/assigned-content/';

export const fetchQiyasExamsUrl = () => '/api/qiyas/exams/';

export const fetchCreateQiyasExamFormDataUrl = () => '/api/qiyas/form-data/';

export const getQiyasQuestionsUrl = (examID: number, sectionID: number) =>
  `/api/qiyas/exams/${examID}/sections/${sectionID}/questions/`;

export const getQiyasQuestionUrl = (examID: number, sectionID: number, questionID?: number) =>
  `/api/qiyas/exams/${examID}/sections/${sectionID}/questions/${questionID}/`;

export const getQiyasExamDetailUrl = (examId?: number) => `/api/qiyas/exams/${examId}/`;

export const getPublishQiyasExamUrl = (examID: number) => `/api/qiyas/exams/${examID}/publish/`;

export const fetchQiyasAttemptedExamsUrl = () => '/api/qiyas/attempted-exams/';

export const fetchExamDetail = (id: string) => `/api/qiyas/exams/${id}/`;
export const fetchQuestionDetail = (examId: number, attemptId: number, questionId: number) =>
  `/api/qiyas/exams/${examId}/attempts/${attemptId}/questions/${questionId}/`;

export const fetchQuestions = (id: string) => `/api/qiyas/exams/${id}/questions/`;
export const fetchResults = (id: string, attempt: string) =>
  `/api/qiyas/exams/${id}/attempts/${attempt}/result/`;

export const createExamAttempt = (id: number) => `/api/qiyas/exams/${id}/attempts/`;

// APIs for B2B Grade Mapping
export const saveGradeMappingAPI = (branch: number) =>
  `/api/b2b-grading-system${branch ? `/branch/${branch}` : ''}/exams/`;

export const pasteSemesterMappingAPI = (branch: number, grade: number, semester: string) =>
  `/api/b2b-grading-system/branch/${branch}/grade/${grade}/semester/${semester}/mapping/`;

export const getGradeMappingFiltersAPI = () => `/api/b2b-grading-system/filters/`;

export const getExamsAPI = (branch: number) =>
  `/api/b2b-grading-system${branch ? `/branch/${branch}` : ''}/exams/`;

export const updateGradeMappingAPI = (mappingId: number, branch: number) =>
  `/api/b2b-grading-system${branch ? `/branch/${branch}` : ''}/exams/${mappingId}/`;

export const getStudentsScoreListAPI = (examId: number) =>
  `/api/b2b-grading-system/exams/${examId}/students/`;

export const getPublishSingleScoreAPI = (examID: number, studentID: number) =>
  `/api/b2b-grading-system/exams/${examID}/students/${studentID}/publish/`;

export const getPublishAllScoresAPI = (examID: number) =>
  `/api/b2b-grading-system/exams/${examID}/publish/`;

export const getStudentSubjectsScoreListAPI = (
  gradeID: number,
  semesterID: number,
  branch: number,
) =>
  `/api/b2b-grading-system${
    branch ? `/branch/${branch}` : ''
  }/grade/${gradeID}/semester/${semesterID}/exam-reports/`;

export const getB2BGradingCertificateAPI = () => '/api/b2b-grading-system/student-certificates/';

export const getCertificateLink = (base64Id: string) =>
  `${APP_API_URL}/api/b2b-grading-system/certificates/${base64Id}/html/`;

export const getIssueCertificateAPI = (branchID: number, studentID: number) =>
  `/api/b2b-grading-system/branch/${branchID}/students/${studentID}/issue-certificate/`;
