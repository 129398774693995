import {
  QuestionInfoPayload as NativeQuestionInfoPayload,
  Question as NativeQuestion,
} from 'store/native-events/types';

export enum QuestionType {
  SingleChoice = 'single_choice',
  MultipleChoice = 'multiple_choice',
  TrueFalse = 'true_false',
  MatchColumn = 'matching_columns',
  DragDrop = 'drag_and_drop',
}

export type questionBankDataType = {
  learningObjectives?: learningObjectivesResponse;
  subjects?: Array<subjectsType>;
  units?: subjectsType;
  lessonResults?: lessonResults;
  sampleQuestions?: questionListResponse;
  subjectsSearch?: Array<subjectsType>;
  unitsSearch?: subjectsType;
  quiz?: sampleQuestionsType;
  submitCounter?: number;
  teacherClassesData?: Array<classesSubjectsType>;
  teacherSubjects?: Array<subjectsListType>;
  teacherUnits?: Array<teacherUnitsAndLessonsType>;
  teacherLessons?: Array<teacherUnitsAndLessonsType>;
  teacherLearningObjectives?: learningObjectivesResponse;
  answerDetailOptions?: Array<answerDetailOptions>;
  questionList?: questionListResponse;
  teacherGrades?: Array<TeacherGradesType>;
  learningObjectiveSmartScore?: number;
};

export type quizTypes = {
  isQuestionStatusUpdated: boolean;
  lastAttemptedProblem?: sampleQuestionsResult;
  nextProblem?: sampleQuestionsResult;
};

export type loadSubjectRequestType = {
  searchQuery: string;
};

export type lessonResults = {
  wrong: number;
  attempted: number;
  total: number;
  correct: number;
};

export type subjectsType = {
  courseId: string;
  displayName: string;
  units?: Array<lessonsType>;
  totalUnits: number;
  totalLessons: number;
};

export type lessonDataTypes = {
  displayName: string;
  usageKey: string;
};

export type lessonsType = {
  lessons: Array<lessonDataTypes>;
  displayName: string;
  usageKey?: string;
};

export type subject = {
  units: Array<lessonsType>;
  display_name: string;
  course_id: string;
};

export type questionListPayloadType = {
  currentPage: number;
  pageSize: number;
  courseId: string;
  lessonId: string;
};

export type Question = {
  id: number;
  grade: TeacherGradesType;
  unit: lessonDataTypes;
  lesson: lessonDataTypes;
  subject: {
    id: string;
    simpleSubject: string;
    subject: string;
  };
  difficultyLevel: number;
  hint: string;
  answerExplanation: AnswerExplanation;
  isSample: boolean;
  statement: string;
  attachment: AttachmentWithName;
  questionType: QuestionType;
  title: string;
  branch: string;
  learningObjective: { id: number; title: string; expectedCorrectAnswers: number };
  addedBy: {
    id: number;
    name: string;
  };
  type: number;
  questions: NativeQuestionInfoPayload[];
};

export type questionListResponse = {
  results: Array<Question>;
  count: number;
  numPages: number;
  currentPage: number;
  next: any;
  start: number;
  previous: any;
};

type CommonKeys = {
  lessonKey: string;
  subjectId: string;
};

export type learningObjectPayload = CommonKeys & {
  page?: number;
};

export type CreateLearningObjectPayload = {
  learningObjectiveTitle: string;
  questionBankLessonKey?: string;
  lessonKey?: string;
  callback?: (type: string, LO?: CreateUpdateLearningObjectResponse, error?: string) => void;
};

export type UpdateLearningObjectPayload = CreateLearningObjectPayload & {
  id: number | string;
};

export type CreateUpdateLearningObjectResponse = Pick<CommonKeys, 'lessonKey'> &
  Pick<CreateLearningObjectPayload, 'questionBankLessonKey'> & {
    id: number;
    title: string;
    learningObjectiveId: number;
  };

export type CreateUpdateLearningObjectPayload =
  | CreateLearningObjectPayload
  | UpdateLearningObjectPayload;

export type unitsObjectPayload = CommonKeys & {
  learningObjectId?: number;
};

export type archiveQuestionPayload = {
  id: number;
  callback: Function;
};

export type fetchUnitsPayload = {
  unitKey: string;
  searchQuery?: string;
};

export type fetchClassesSearchPayload = {
  searchQuery?: string;
};

export type fetchLessonsPayload = {
  subjectId: string;
  learningObjectId: number;
  lessonKey: string;
  pageSize?: number;
};

export type fetchSampleQuestionsPayload = {
  subjectId: string;
  lessonLearningObjectiveId: number;
  pageNumber: number;
  pageSize?: number;
};

export type learningObjectives = {
  id: number;
  title: string;
  totalQuestions: number;
  correctAttempts?: number;
  expectedCorrectAnswers: number;
  lessonLearningObjectiveId?: number;
  attemptedQuestions: number;
  totalSampleQuestions?: number;
  smartScore: number;
};

export type learningObjectivesResponse = {
  start: number;
  numPages: number;
  previous: null | string;
  count: number;
  next: null | string;
  currentPage: number;
  results: Array<learningObjectives>;
};

export type qBSubjectLessonScoreboardResponse = {
  wrong: number;
  target: number;
  total: number;
  score: number;
  correct: number;
  unit: {
    id: string;
    name: string;
  };
  lesson: {
    id: string;
    name: string;
  };
  subject: {
    id: string;
    name: string;
  };
};

export type sampleQuestionsResult = {
  id: number;
  problemKey: string;
  difficultyLevel: number;
  isAttempted: boolean;
  isCorrect: boolean;
  lastSubmissionTime: string;
  feedbackText: string;
  feedbackImage: string;
};

export type sampleQuestionsType = {
  count: number;
  numPages: number;
  currentPage: number;
  results: Array<sampleQuestionsResult>;
  next: null;
  start: number;
  previous: null;
};

export type subjectsListType = {
  displayName: string;
  courseKey: string;
};

export type teacherQuestionLocatorsPayload = {
  subjectKey: string;
  unitKey?: string | number;
};

export type teacherUnitsAndLessonsType = {
  displayName: string;
  blockTypeALW: string;
  usageKey: string;
};

export enum answerDetailOptionsEnums {
  MULTIPLE_CHOICE = 'multiple_choice',
  SINGLE_CHOICE = 'single_choice',
  TRUE_FALSE = 'true_false',
}

export type answerDetailOptions = {
  id: number;
  title: answerDetailOptionsEnums;
};

export type classesSubjectsType = {
  className: string;
  classId: string;
  subjects: Array<subjectsType>;
};

export type ValueLabel = {
  value: number | string;
  label: string;
};

type AttachmentWithName = {
  imageUrl: string;
  videoUrl: string;
  externalUrl?: string;
  name?: string;
};

type CommonAnswerCreationProps = {
  id?: number;
  attachment?: AttachmentWithName;
  isDeleted: boolean;
  statement: string;
};

type AnswerExplanation = {
  explanationText: string;
  attachment?: AttachmentWithName;
};

export type QuestionCreationInitialForm = {
  id?: number; // needed for edit
  showOverallDescription?: boolean;
  groupDescription?: {
    statement: string;
    attachment?: AttachmentWithName;
  };
  title: string;
  grade: ValueLabel | null;
  subject: ValueLabel | null;
  unit: ValueLabel | null;
  lesson: ValueLabel | null;
  learningObjective: ValueLabel | null;
  questionType: QuestionType | null;
  isSample: boolean;
  difficultyLevel: number; // create enum for this one
  hint: string;
  choiceQuestion: {
    id?: number;
    statement: string;
    answerExplanation: AnswerExplanation;
    attachment?: AttachmentWithName;
    singleChoiceCorrect: string;
    multipleChoiceCorrect: string[];
    questionGroup?: number;
    options: Array<
      CommonAnswerCreationProps & {
        isCorrect: boolean;
      }
    >;
  };
  otherQuestions: {
    questions: {
      id?: number; // question id required for edit
      isDeleted?: boolean;
      statement: string;
      answerExplanation: AnswerExplanation;
      attachment?: AttachmentWithName;
      isCorrect?: boolean;
      answers?: Array<
        CommonAnswerCreationProps & {
          isCorrect: boolean;
          answerKey?: string; // required for drag drop question creation
        }
      >;
    }[];
    dropAnswers?: {
      id?: number;
      statement: string;
      dropAnswerKey: string;
      questionIndex: number;
      dropNumber: number;
    }[];
  };
};

export enum ActionTypes {
  Publish = 'publish',
  Discard = 'discard',
}

export type CreateQuestionPayload = {
  questionGroupId?: number; // needed in case of edit
  questionPool: {
    subject: string | number | undefined;
    lessonKey: string | number | undefined;
    unitKey: string | number | undefined;
  };
  questionGroup: {
    difficultyLevel: number; // for question bank
    learningObjective: string | number | undefined;
    hint: string;
    answerExplanation: AnswerExplanation | undefined;
  };
  questions: Omit<NativeQuestionInfoPayload, 'answerExplanation'>[];
  isEditing: boolean;
  callback: (type: string, action: ActionTypes | undefined, message?: string) => void;
};

export type CreateQuestionResponse = NativeQuestion & {
  difficultyLevel: number | null;
};

export type uploadQuestionImagePayload = {
  problem_key?: string;
  callback?: (action: string, response: any) => void;
  image: File;
};

export type TeacherGradesType = {
  id: number;
  label: string;
  gradeValue: number;
};

export type TeacherSubjectParam = {
  gradeId?: string;
};

export type SelectFieldType = {
  value: string | undefined;
  label?: string;
};

export type SelectFieldMetaType = {
  action: string;
  removedValue: SelectFieldType;
  name: string;
};

export type LearningObjectiveSmartScoreResponse = {
  id: number;
  user: number;
  learningObjectiveId: number;
  learningObjectiveTitle: string;
  learningObjectiveExpectedCorrectAnswers: number;
  smartScore: number;
  success?: boolean;
};

export type FetchLearningObjectiveSmartScorePayload = {
  lessonKey: string;
  learningObjectiveId: number;
  subjectId: string;
};

export type UpdateLearningObjectiveSmartScorePayload = {
  subjectId: string;
  lessonKey: string;
  learningObjectiveId: number;
  smartScore: number;
  problemKey: string;
};

export type UpdateExpectedAnswersPayload = {
  learningObjectiveId: number;
  expectedCorrectAnswers: number;
  callback: (isSuccess: boolean) => void;
};

export type DialogType = 'wrong' | 'complete' | 'correct';
