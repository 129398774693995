import React, { ChangeEvent, ReactElement, forwardRef } from 'react';
import cn from 'classnames';

import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';

import styles from './InputField.module.scss';
import ErrorHelperText from './ErrorHelperText';
import { FieldLabel } from './FieldLabel/FieldLabel';
import { TextArea, TextAreaProps } from './TextArea/TextArea';
import { IconCommon } from '../IconCommon/IconCommon';
import { Spinner } from '../Spinner/Spinner';

type TextAreaFieldProps = TextAreaProps & {
  title?: string;
  error?: string | boolean;
  helperText?: string;
  children?: ReactElement;
  attachmentProps?: {
    loading?: boolean;
    disabled?: boolean;
    attachmentFieldName: string;
    attachmentTypes: string;
    uploadHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  };
};

export const TextAreaField = forwardRef<HTMLInputElement, TextAreaFieldProps>(
  (
    {
      id,
      title = '',
      children,
      error = '',
      fullWidth,
      helperText,
      attachmentProps,
      ...textAreaProps
    },
    fileInputRef,
  ) => {
    const isError = !!error;
    const isGradeOneToThree = useIsGradeOneToThree();

    const {
      uploadHandler,
      attachmentTypes,
      attachmentFieldName,
      disabled = false,
      loading = false,
    } = attachmentProps || {};

    return (
      <div
        className={cn(styles['form-group'], fullWidth && styles.full, {
          [styles['error-group']]: isError,
          [styles.grade]: isGradeOneToThree,
          [styles.attachmentError]: attachmentProps && isError,
        })}
      >
        <FieldLabel title={title} htmlFor={id} />
        <div className={cn({ [styles.formAttachment]: attachmentProps })}>
          <TextArea {...textAreaProps} id={id} />
          {attachmentProps && (
            <label className={cn(styles.formAttachment__attach, { [styles.disabled]: disabled })}>
              {loading ? <Spinner /> : <IconCommon className="icon-attachment" />}
              <input
                id="file"
                type="file"
                name={attachmentFieldName}
                accept={attachmentTypes}
                onChange={uploadHandler}
                ref={fileInputRef}
                disabled={loading || disabled}
              />
            </label>
          )}
        </div>
        <ErrorHelperText helperText={helperText} error={error} />
        {children}
      </div>
    );
  },
);
