import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  getTeacherQuestionsAction,
  deleteTeacherQuestionAction,
  getChildTeacherDetailAction,
  getChildDetailAction,
  postUpdateAnswerAction,
  askQuestionAction,
} from './actions';
import {
  getTeacherQuestions,
  getChildTeacherDetail,
  getChildDetail,
  deleteTeacherQuestion,
  postUpdateAnswer,
  askQuestion,
} from './api';
import { PaginatedTeacherQuestion, ChildTeacherDetail, ChildDetail, TeacherAnswer } from './types';

function* getTeacherQuestionsSaga({
  payload,
}: ReturnType<typeof getTeacherQuestionsAction.request>): SagaIterator<void> {
  try {
    const teacherQuestions: PaginatedTeacherQuestion = yield call(getTeacherQuestions, payload);

    yield put(getTeacherQuestionsAction.success(teacherQuestions));
  } catch (error) {
    yield put(getTeacherQuestionsAction.failure(error));
  }
}

function* deleteTeacherQuestionSaga({
  payload,
}: ReturnType<typeof deleteTeacherQuestionAction.request>): SagaIterator<void> {
  try {
    const { callback, ...deleteTeacherQuestionPayload } = payload;

    yield call(deleteTeacherQuestion, deleteTeacherQuestionPayload);

    yield put(deleteTeacherQuestionAction.success());
    callback('success');
  } catch (error) {
    yield put(deleteTeacherQuestionAction.failure(error));
  }
}

function* postUpdateAnswerSaga({
  payload,
}: ReturnType<typeof postUpdateAnswerAction.request>): SagaIterator<void> {
  try {
    const { callback, ...postAnswerPayload } = payload;

    const postedAnswer: TeacherAnswer = yield call(postUpdateAnswer, postAnswerPayload);
    yield put(postUpdateAnswerAction.success(postedAnswer));

    callback('success');
  } catch (error) {
    payload?.callback?.('error', error);
    yield put(postUpdateAnswerAction.failure(error));
  }
}

function* getChildTeacherDetailSaga({
  payload,
}: ReturnType<typeof getChildTeacherDetailAction.request>): SagaIterator<void> {
  try {
    const childTeacherDetail: ChildTeacherDetail = yield call(getChildTeacherDetail, payload);
    yield put(getChildTeacherDetailAction.success(childTeacherDetail));
  } catch (error) {
    yield put(getChildTeacherDetailAction.failure(error));
  }
}

function* getChildDetailSaga({
  payload,
}: ReturnType<typeof getChildDetailAction.request>): SagaIterator<void> {
  try {
    const childDetail: ChildDetail = yield call(getChildDetail, payload);
    yield put(getChildDetailAction.success(childDetail));
  } catch (error) {
    yield put(getChildDetailAction.failure(error));
  }
}

function* askQuestionSaga({
  payload,
}: ReturnType<typeof askQuestionAction.request>): SagaIterator<void> {
  try {
    const data = yield call(askQuestion, payload);
    yield put(askQuestionAction.success(data));
    payload.callback?.('success');
  } catch (error) {
    payload.callback?.('error');
    yield put(askQuestionAction.failure(error));
  }
}

function* childTeacherDetailQuestionSaga(): SagaIterator {
  yield all([
    takeEvery(getTeacherQuestionsAction.request, getTeacherQuestionsSaga),
    takeEvery(deleteTeacherQuestionAction.request, deleteTeacherQuestionSaga),
    takeEvery(postUpdateAnswerAction.request, postUpdateAnswerSaga),
    takeEvery(getChildTeacherDetailAction.request, getChildTeacherDetailSaga),
    takeEvery(getChildDetailAction.request, getChildDetailSaga),
    takeEvery(askQuestionAction.request, askQuestionSaga),
  ]);
}

export default childTeacherDetailQuestionSaga;
