import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import { isEqual } from 'lodash/fp';

import API from 'utils/api';
import { getChildrenRegistrationAPI, getRegistrationAPI } from 'utils/apiEndpoints';

import { Role } from '../account/types';
import {
  RegistrationType,
  ChildrenType,
  RegisterParentType,
  OtpVerificationType,
  OtpSendRequestType,
  trackSignupRequestType,
} from './types';
import {
  dataParentAdapter,
  dataStudentAdapter,
  childrenAdapter,
  dataInviteParentAdapter,
} from './adapter';

export async function sendRegistration(data: RegistrationType): Promise<void> {
  const adapter = isEqual(Role.parent, data.role) ? dataParentAdapter : dataStudentAdapter;
  const response = await API.post(
    getRegistrationAPI(),
    snakeCaseKeys(adapter(data), { deep: true }),
  );

  return camelcaseKeys(response.data, { deep: true });
}

export async function validationRegistration(name: string, value: string): Promise<any> {
  const body = new FormData();
  body.append(name, value);
  const response = await API.post('api/mentora/auth/validation/alw-registration', body, {
    headers: { 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  });
  return response;
}

export async function sendRegistrationChildren(
  children: Array<ChildrenType>,
): Promise<Array<{ email: string }>> {
  const response = await API.post(
    getChildrenRegistrationAPI(),
    snakeCaseKeys(childrenAdapter(children), { deep: true }),
  );

  return camelcaseKeys(response.data, { deep: true });
}

export async function registrationParent(data: RegisterParentType): Promise<void> {
  await API.post(getRegistrationAPI(), snakeCaseKeys(dataInviteParentAdapter(data)));
}

export async function invitationAccount(code: string): Promise<string> {
  const response = await API.get(`/api/alw/invitation/check/${code}`);
  return response.data.success;
}

export async function otpVerificationRequest(values: OtpVerificationType): Promise<any> {
  const response = await API.post('/api/otp/verify', {
    signature: values.signature,
    otp: values.Otp,
    email: values.email || undefined,
    phone: values.phone,
  });
  return response.data;
}

export async function otpSendingRequest(values: OtpSendRequestType): Promise<any> {
  const response = await API.post('/api/otp/generate', {
    email: values.email,
    phone: values.phone,
  });
  return response.data;
}

export async function getCountriesListRequest(): Promise<any> {
  const response = await API.get('/api/mentora/auth/countries/');
  return camelcaseKeys(response.data, { deep: true });
}

export async function trackSignup(bodyPayload: trackSignupRequestType): Promise<void> {
  await API.post('/api/mentora/auth/track-signup/', bodyPayload);
}
