import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';
import {
  exportStudentsList,
  exportTeachersList,
  studentsListAPI,
  teachersListAPI,
  regeneratePasswordAPI,
} from 'utils/apiEndpoints';
import { getSelectedDuration } from 'utils/helpers';

import {
  SchoolUserListResponse,
  TeachersListPayload,
  TeachersProgressListPayload,
  RegeneratePasswordPayload,
} from './types';

export type param = {
  page: number;
  page_size: number;
  name?: string;
  progress?: boolean;
  start_date?: string;
  end_date?: string;
  sort_by?: string;
  sort_type?: string;
  filter?: string;
};

export async function getTeachersProgressList(
  payload: TeachersProgressListPayload,
): Promise<SchoolUserListResponse[]> {
  const duration = getSelectedDuration(payload.statusType, payload.semesterStartDate);
  const { currentPage, pageSize, searchText, sorting } = payload;
  const queryParams: param = {
    page_size: pageSize,
    page: currentPage,
    progress: true,
    sort_by: sorting?.id,
    sort_type: sorting?.desc ? 'desc' : undefined,
    ...duration,
  };
  const searchName = searchText?.trim();
  if (searchName) {
    queryParams.name = searchName;
  }
  const response = await API.get(teachersListAPI(payload.branch), {
    params: queryParams,
  });
  return camelcaseKeys(response.data, { deep: true });
}
export async function getSchoolTeachersList({
  currentPage,
  pageSize,
  searchText,
  sorting,
  filter,
  branch,
}: TeachersListPayload): Promise<SchoolUserListResponse> {
  const queryParams: param = {
    page_size: pageSize,
    page: currentPage,
    sort_by: sorting?.id,
    sort_type: sorting?.desc ? 'desc' : undefined,
    filter,
  };
  if (searchText?.trim()) {
    queryParams.name = searchText?.trim();
  }
  const response = await API.get(teachersListAPI(branch), {
    params: queryParams,
  });
  return camelcaseKeys(response.data, { deep: true });
}
export async function getSchoolStudentsList({
  currentPage,
  pageSize,
  searchText,
  sorting,
  filter,
  branch,
}: TeachersListPayload): Promise<SchoolUserListResponse> {
  const queryParams: param = {
    page_size: pageSize,
    page: currentPage,
    sort_by: sorting?.id,
    sort_type: sorting?.desc ? 'desc' : undefined,
    filter,
  };
  if (searchText?.trim()) {
    queryParams.name = searchText?.trim();
  }
  const response = await API.get(studentsListAPI(branch), {
    params: queryParams,
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getUsersListViaEmail(
  branch: number,
  isStudent: boolean,
): Promise<{ message: string }> {
  const apiFunc = isStudent ? exportStudentsList : exportTeachersList;
  const response = await API.get(apiFunc(branch));
  return camelcaseKeys(response.data, { deep: true });
}

export async function regenerateUserPassword(payload: RegeneratePasswordPayload): Promise<void> {
  const response = await API.post(regeneratePasswordAPI(payload.branch), snakeCaseKeys(payload));
  return camelcaseKeys(response.data, { deep: true });
}
