import { createAsyncAction, createAction } from 'typesafe-actions';

import { LiveClassRecommendationItem } from 'store/reports/types';

import {
  ScheduleEventType,
  ScheduleRequestType,
  CreateEventRequestType,
  TableViewResponseType,
  EventDetailType,
  LiveEventType,
  TeacherSessionQARequestType,
  GetStudentLiveQuestionsType,
  ScheduleFormOptionsType,
  ScheduleCalendarType,
  BMSchedulePayload,
  BranchScheduleTableViewResponseType,
  BMTableSchedulePayload,
  AddCommentPayload,
  Comment,
  GetLiveScheduleRequestType,
  ScheduleErrorType,
  StudentLiveQuestionsType,
  DeleteAttachmentType,
  TeacherSessionQAEventType,
  LiveAnswerType,
  UpdateAnswerRequest,
  PostStudentQuestionRequest,
} from './types';

export const loadScheduleAction = createAsyncAction(
  '@@schedule/LOAD_REQUEST',
  '@@schedule/LOAD_SUCCESS',
  '@@schedule/LOAD_FAILURE',
)<ScheduleRequestType, ScheduleEventType[], string>();

export const loadScheduleDetailAction = createAsyncAction(
  '@@scheduleDetail/LOAD_REQUEST',
  '@@scheduleDetail/LOAD_SUCCESS',
  '@@scheduleDetail/LOAD_FAILURE',
)<number, EventDetailType, string>();

export const loadScheduleFormOptions = createAsyncAction(
  '@@scheduleFilters/LOAD_REQUEST',
  '@@scheduleFilters/LOAD_SUCCESS',
  '@@scheduleFilters/LOAD_FAILURE',
)<number, ScheduleFormOptionsType, string>();

export const loadBranchCalenderScheduleOptions = createAsyncAction(
  '@@scheduleCalendar/LOAD_REQUEST',
  '@@scheduleCalendar/LOAD_SUCCESS',
  '@@scheduleCalendar/LOAD_FAILURE',
)<BMSchedulePayload, ScheduleCalendarType[], string>();

export const loadTableListScheduleAction = createAsyncAction(
  '@@scheduleTableList/LOAD_REQUEST',
  '@@scheduleTableList/LOAD_SUCCESS',
  '@@scheduleTableList/LOAD_FAILURE',
)<TableViewResponseType, TableViewResponseType, string>();

export const createScheduleAction = createAsyncAction(
  '@@schedule/CREATE_REQUEST',
  '@@schedule/CREATE_SUCCESS',
  '@@schedule/CREATE_FAILURE',
)<CreateEventRequestType, undefined, string>();

export const loadBranchTableSchedules = createAsyncAction(
  '@@scheduleTable/LOAD_REQUEST',
  '@@scheduleTable/LOAD_SUCCESS',
  '@@scheduleTable/LOAD_FAILURE',
)<BMTableSchedulePayload, BranchScheduleTableViewResponseType, string>();

export const addCommentAction = createAsyncAction(
  '@@addComment/LOAD_REQUEST',
  '@@addComment/LOAD_SUCCESS',
  '@@addComment/LOAD_FAILURE',
)<AddCommentPayload, undefined, string>();

export const loadCommentAction = createAsyncAction(
  '@@loadComments/LOAD_REQUEST',
  '@@loadComments/LOAD_SUCCESS',
  '@@loadComments/LOAD_FAILURE',
)<AddCommentPayload, Comment[], string>();

export const deleteScheduleAction = createAsyncAction(
  '@@schedule/DELETE_REQUEST',
  '@@schedule/DELETE_SUCCESS',
  '@@schedule/DELETE_FAILURE',
)<any, number, string>();

export const createRecordingLinkAction = createAsyncAction(
  '@@recordingLink/CREATE_LINK_REQUEST',
  '@@recordingLink/CREATE_LINK_SUCCESS',
  '@@recordingLink/CREATE_LINK_FAILURE',
)<ScheduleEventType, ScheduleEventType, string>();

export const deleteRecordingLinkAction = createAsyncAction(
  '@@recordingLink/DELETE_LINK_REQUEST',
  '@@recordingLink/DELETE_LINK_SUCCESS',
  '@@recordingLink/DELETE_LINK_FAILURE',
)<ScheduleEventType, number, string>();

export const deleteAttachmentAction = createAsyncAction(
  '@@/schedule/DELETE_ATTACHMENT_REQUEST',
  '@@/schedule/DELETE_ATTACHMENT_SUCCESS',
  '@@/schedule/DELETE_ATTACHMENT_FAILURE',
)<DeleteAttachmentType, number, string>();

export const loadJoinMeetingUrl = createAsyncAction(
  '@@joinmeeting/LOAD_REQUEST',
  '@@joinmeeting/LOAD_SUCCESS',
  '@@joinmeeting/LOAD_FAILURE',
)<any, any, string>();

export const refreshJoinMeetingUrl = createAsyncAction(
  '@@refreshmeeting/LOAD_REQUEST',
  '@@refreshmeeting/LOAD_SUCCESS',
  '@@refreshmeeting/LOAD_FAILURE',
)<any, any, string>();

export const addEventAttachmentsAction = createAsyncAction(
  '@@event/ADD_ATTACHMENT_REQUEST',
  '@@event/ADD_ATTACHMENT_SUCCESS',
  '@@event/ADD_ATTACHMENT_FAILURE',
)<any, any, string>();

export const loadTeacherEventAttachmentsAction = createAsyncAction(
  '@@event/LOAD_TEACHER_ATTACHMENT_REQUEST',
  '@@event/LOAD_TEACHER_ATTACHMENT_SUCCESS',
  '@@event/LOAD_TEACHER_ATTACHMENT_FAILURE',
)<string | undefined, any, string>();

export const declineEventRequestAction = createAsyncAction(
  '@@event/DECLINE_REQUEST',
  '@@event/DECLINE_SUCCESS',
  '@@event/DECLINE_FAILURE',
)<any, any, string>();

export const liveEventRatingAction = createAsyncAction(
  '@@event/RATE_REQUEST',
  '@@event/RATE_SUCCESS',
  '@@event/RATE_FAILURE',
)<LiveEventType, EventDetailType, string>();

export const updateLiveEventRatingAction = createAsyncAction(
  '@@event/UPDATE_RATE_REQUEST',
  '@@event/UPDATE_RATE_SUCCESS',
  '@@event/UPDATE_RATE_FAILURE',
)<LiveEventType, EventDetailType, string>();

export const getStudentLiveQuestions = createAsyncAction(
  '@@event/STUDENT_QUESTIONS_REQUEST',
  '@@event/STUDENT_QUESTIONS_SUCCESS',
  '@@event/STUDENT_QUESTIONS_FAILURE',
)<GetStudentLiveQuestionsType, StudentLiveQuestionsType[], string>();

export const getTeacherLiveQuestions = createAsyncAction(
  '@@event/TEACHER_QUESTIONS_REQUEST',
  '@@event/TEACHER_QUESTIONS_SUCCESS',
  '@@event/TEACHER_QUESTIONS_FAILURE',
)<TeacherSessionQARequestType, TeacherSessionQAEventType[], string>();

export const postStudentLiveQuestions = createAsyncAction(
  '@@event/POST_STUDENT_QUESTION_REQUEST',
  '@@event/POST_STUDENT_QUESTION_SUCCESS',
  '@@event/POST_STUDENT_QUESTION_FAILURE',
)<PostStudentQuestionRequest, StudentLiveQuestionsType, string>();

export const getLiveEventQuestions = createAsyncAction(
  '@@event/LIVE_QUESTION_REQUEST',
  '@@event/LIVE_QUESTION_QUESTION_SUCCESS',
  '@@event/LIVE_QUESTION_QUESTION_FAILURE',
)<any, any, string>();

export const postLiveEventAnswer = createAsyncAction(
  '@@event/LIVE_ANSWER_REQUEST',
  '@@event/LIVE_ANSWER_SUCCESS',
  '@@event/LIVE_ANSWER_FAILURE',
)<UpdateAnswerRequest, LiveAnswerType, string>();

export const updateLiveEventAnswer = createAsyncAction(
  '@@event/LIVE_ANSWER_UPDATE_REQUEST',
  '@@event/LIVE_ANSWER_UPDATE_SUCCESS',
  '@@event/LIVE_ANSWER_UPDATE_FAILURE',
)<UpdateAnswerRequest, LiveAnswerType, string>();

export const deleteLiveEventQuestion = createAsyncAction(
  '@@event/DELETE_QUESTION_REQUEST',
  '@@event/DELETE_QUESTION_SUCCESS',
  '@@event/DELETE_QUESTION_FAILURE',
)<any, any, string>();

export const getLiveScheduleData = createAsyncAction(
  '@@schedule/GET_LIVE_SCHEDULE_DATA_REQUEST',
  '@@schedule/GET_LIVE_SCHEDULE_DATA_SUCCESS',
  '@@schedule/GET_LIVE_SCHEDULE_DATA_FAILURE',
)<GetLiveScheduleRequestType, LiveClassRecommendationItem, ScheduleErrorType>();

export const resetJoinUrl = createAction('@@joinUrl/RESET')();
export const resetComments = createAction('@@resetComment/RESET')();
export const resetScheduleAction = createAction('@@schedule/RESET')();
export const resetScheduleDetailAction = createAction('@@scheduleDetail/RESET')();
export const resetTeacherEventAttachments = createAction('@@teacher_event_attachments/RESET')();

export const scheduleStartDateAction = createAction('@@schedule/UPDATE_START_DATE')<Date>();
export const scheduleEndDateAction = createAction('@@schedule/UPDATE_END_DATE')<Date>();
export const scheduleEndDateCompAction = createAction('@@schedule/UPDATE_END_DATE_COMP')<Date>();
export const scheduleStartDateCompAction = createAction(
  '@@schedule/UPDATE_START_DATE_COMP',
)<Date>();
export const scheduleActiveStartDateCompAction = createAction(
  '@@schedule/UPDATE_ACTIVE_START_DATE_COMP',
)<Date>();
export const scheduleStartDateForApiAction = createAction(
  '@@schedule/UPDATE_START_DATE_FOR_API',
)<Date>();
