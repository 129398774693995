import { ROUTES } from 'constants/routes';
import { getSvgURL } from 'lib';
import { getIntl } from 'utils/intl';

const getCommonMenu = () => {
  const { formatMessage } = getIntl();

  return [
    {
      id: 'sidebar__native_events',
      path: ROUTES.nativeEvents,
      label: formatMessage({
        id: 'native.events.label',
        defaultMessage: 'Native Events',
      }),
      image: getSvgURL('native-events'),
    },
  ];
};

export const alwAdminMenu = () => getCommonMenu();

export const contentAuthorMenu = () => {
  const { formatMessage } = getIntl();

  return [
    ...getCommonMenu(),
    {
      id: 'sidebar__qiyas',
      path: ROUTES.qiyas,
      label: formatMessage({ id: 'qiyas.title', defaultMessage: 'Qiyas Simulation' }),
      icon: 'exercise',
    },
  ];
};
