import React, { lazy } from 'react';

import { ROUTES } from 'constants/routes';
import {
  DISABLE_B2C_GROUP_CHAT,
  DISABLE_B2C_REWARDS,
  DISABLE_B2B_GRADING,
  DISABLE_ARS,
} from 'constants/settings';
import { Role, SchoolRole, AdminRole } from 'store/account/types';
import { getRolesSemesterWise, getSchoolRolesSemesterWise } from 'utils/summerSemesterUtils';

import { RoleRouteInfo } from './types';

// auth routes
const Login = lazy(() => import('components/Login'));
const ManagementLogin = lazy(() => import('components/ManagementLogin'));
const ForgotPassword = lazy(() => import('components/ForgotPassword'));
const SetPassword = lazy(() => import('components/SetPassword'));
const ConfirmEmail = lazy(() => import('components/ConfirmEmail'));
const Registration = lazy(() => import('components/Registration'));
const RegistrationV2 = lazy(() => import('components/RegistrationV2'));
const Activate = lazy(() => import('components/Activate'));
const SSO = lazy(() => import('components/SSO'));

// normal routes
const Exercises = lazy(() => import('components/Exercises'));
const Promotion = lazy(() => import('components/Promotion'));
const GroupChat = lazy(() => import('components/GroupChat'));
const HelpLayout = lazy(() => import('components/Help/HelpLayout'));
const QuestionBankQuiz = lazy(() => import('components/QuestionBank/Quiz'));
const Reinforcements = lazy(() => import('components/Exams/Reinforcements'));
const SubjectsLayout = lazy(() => import('components/QuestionBank/Subjects'));
const WrappedGradeComponent = lazy(() => import('components/WrappedGradeComponent'));
const StudentReports = lazy(() => import('components/Reports/Student/StudentReportsLayout'));
const LearningObjectivesLayout = lazy(() => import('components/QuestionBank/LearningObjectives'));
const CreateNewQuestion = lazy(() => import('components/QuestionBank/Questions/CreateNewQuestion'));
const SchoolStudentDashBoard = lazy(() => import('components/SchoolStudentDashboard'));
const SchoolTeacherDashBoard = lazy(() => import('components/SchoolTeacherDashboard'));
const TimeTable = lazy(() => import('components/Schedule/TimeTable/Grades'));
const AdminReports = lazy(() => import('components/Reports/Admin'));
const AdminClassroomDetails = lazy(
  () => import('components/Reports/Admin/ClassesPerformance/BranchClassroomDetail'),
);
const GradeWiseTimeTable = lazy(
  () => import('components/Schedule/TimeTable/GradeWiseSchedule/GradeWiseSchedule'),
);
const SchoolBranches = lazy(() => import('components/SchoolBranches/SchoolBranches'));

const QuestionsDetailView = lazy(
  () => import('components/QuestionBank/Questions/QuestionsDetailView'),
);
// new routes
const SuccessPayment = lazy(() => import('components/Payment'));
const RoleSelection = lazy(() => import('components/RoleSelection'));
// const AddingSubject = lazy(() => import('components/AddingSubject'));
const SchoolTeachersList = lazy(() => import('components/SchoolTeachersList/SchoolTeachersList'));
const PreparationMaterial = lazy(
  () => import('components/PreparationMaterial/PreparationMaterial'),
);
const TeacherLiveSessionQuestions = lazy(
  () =>
    import(
      'components/LiveSessionQuestions/TeacherLiveSessionQuestions/TeacherLiveSessionQuestions'
    ),
);
const TeacherSessionQAs = lazy(
  () => import('components/LiveSessionQuestions/TeacherLiveSessionQuestions/TeacherSessionQAs'),
);
const SubscriptionsNew = lazy(() => import('components/Subscription'));

const LiveSessionsSchedule = lazy(() => import('components/Schedule/Admin'));
const EventDetail = lazy(() => import('components/EventDetail'));
const GradeDetail = lazy(() => import('components/Reports/Teacher/GradeDetail'));
const GradesList = lazy(() => import('components/Reports/Teacher/GradesList'));
const SemesterScheduleDetail = lazy(
  () => import('components/SemesterSchedule/SemesterScheduleDetail'),
);
const SemesterSchedule = lazy(() => import('components/SemesterSchedule'));
const StudentProgressDetails = lazy(
  () => import('components/Reports/Admin/StudentsProgress/StudentClassroomDetails'),
);

const TeacherScheduleList = lazy(
  () => import('components/FlexibleSchedule/TeacherScheduleList/TeacherScheduleList'),
);

const ScheduleSubjectListing = lazy(
  () => import('components/FlexibleSchedule/ScheduleSubjectListing/ScheduleSubjectListing'),
);

const FlexibleWeeklySchedule = lazy(
  () => import('components/FlexibleSchedule/WeeklySchedule/WeeklySchedule'),
);

const AlertLogsDetails = lazy(
  () => import('components/Reports/Admin/TeacherAlertLogs/AlertLogDetails'),
);
const ChildDashboard = lazy(() => import('components/Dashboard/Child'));
const ChildTeachers = lazy(() => import('components/Dashboard/Parent/Teachers'));
const ChildTeacherDetailQuestion = lazy(() => import('components/ChildTeacherDetailQuestion'));
const ParentsQuestions = lazy(() => import('components/ParentsQuestions'));
const NativeEvents = lazy(() => import('components/NativeEvents'));
const Qiyas = lazy(() => import('components/Qiyas'));
const GradeMapping = lazy(() => import('components/grading-system/components/grade-mapping'));
const GradeReports = lazy(() => import('components/grading-system/components/grade-reports'));

export type LayoutRoutesType = {
  routes: RoleRouteInfo[];
  layoutType: 'gradeOneToThree' | 'common' | 'noLayout';
};

export const getAuthRoutes = (isV1?: boolean): RoleRouteInfo[] => {
  return [
    {
      appRoles: {},
      path: ROUTES.login,
      component: <Login />,
      exact: false,
    },
    {
      appRoles: {},
      path: ROUTES.managementLogin,
      component: <ManagementLogin />,
      exact: false,
    },
    {
      appRoles: {},
      path: ROUTES.forgotPassword,
      component: <ForgotPassword />,
      exact: false,
    },
    {
      appRoles: {},
      path: ROUTES.forgotB2BPassword,
      component: <ForgotPassword isSchoolSystem />,
      exact: false,
    },
    {
      appRoles: {},
      path: ROUTES.setPassword,
      component: <SetPassword />,
      exact: false,
    },
    {
      appRoles: {},
      path: ROUTES.confirmEmail,
      component: <ConfirmEmail />,
      exact: false,
    },
    {
      appRoles: {},
      path: ROUTES.activate,
      component: <Activate />,
      exact: false,
    },
    {
      appRoles: {},
      path: ROUTES.SSO,
      component: <SSO />,
      exact: false,
    },
    {
      appRoles: {},
      path: ROUTES.registration,
      component: isV1 ? <Registration /> : <RegistrationV2 />,
      exact: false,
    },
  ];
};

export const LayoutRoutes: LayoutRoutesType[] = [
  {
    layoutType: 'noLayout',
    routes: [
      {
        appRoles: {
          roles: [Role.student, Role.parent],
        },
        path: [ROUTES.successPayment, ROUTES.successPaymentUsername],
        component: <SuccessPayment />,
        exact: false,
      },
      {
        appRoles: {
          roles: [Role.student, Role.parent],
        },
        path: [ROUTES.subscriptions, ROUTES.subscriptionsUsername],
        component: <SubscriptionsNew />,
        exact: false,
      },
      {
        appRoles: {
          roles: [Role.teacherAndMentor],
        },
        path: [ROUTES.roleSelection],
        component: <RoleSelection />,
        exact: true,
      },
      {
        appRoles: {
          roles: [Role.student],
          schoolRoles: [SchoolRole.schoolStudent],
        },
        path: ROUTES.semesterSubjects,
        component: <WrappedGradeComponent route={ROUTES.semesterSubjects} />,
        exact: true,
      },
      {
        appRoles: { schoolRoles: [SchoolRole.schoolBranchManager, SchoolRole.schoolAdmin] },
        path: ROUTES.selectBranch,
        component: <SchoolBranches />,
        exact: true,
      },
    ],
  },
  {
    layoutType: 'gradeOneToThree',
    routes: [
      {
        appRoles: {
          roles: getRolesSemesterWise([Role.student]),
        },
        path: ROUTES.lab,
        component: <WrappedGradeComponent route={ROUTES.lab} />,
        exact: false,
      },
      {
        appRoles: {
          roles: getRolesSemesterWise([Role.student]),
        },
        path: ROUTES.games,
        component: <WrappedGradeComponent route={ROUTES.games} />,
        exact: false,
      },
    ],
  },
  {
    layoutType: 'common',
    routes: [
      {
        appRoles: {},
        path: [
          ROUTES.root,
          ROUTES.currentWeekLessons,
          ROUTES.overdueLessons,
          ROUTES.additionalLessons,
        ],
        component: <WrappedGradeComponent route={ROUTES.root} />,
        exact: true,
      },
      {
        appRoles: {
          roles: [Role.student, Role.parent],
          schoolRoles: [SchoolRole.schoolStudent],
        },
        path: ROUTES.weeklyPlan,
        component: <WrappedGradeComponent route={ROUTES.weeklyPlan} />,
        exact: false,
      },
      {
        appRoles: {
          schoolRoles: [SchoolRole.schoolTeacher, SchoolRole.schoolStudent],
        },
        path: ROUTES.Chat,
        component: <WrappedGradeComponent route={ROUTES.Chat} />,
        exact: false,
      },
      {
        appRoles: {
          roles: getRolesSemesterWise([Role.student, Role.mentor, Role.alwTeacher]),
        },
        path: ROUTES.lessonPreview,
        component: <WrappedGradeComponent route={ROUTES.lessonPreview} />,
        exact: false,
      },
      {
        appRoles: {
          roles: getRolesSemesterWise([Role.student, Role.mentor]),
          schoolRoles: getSchoolRolesSemesterWise([
            SchoolRole.schoolTeacher,
            SchoolRole.schoolStudent,
          ]),
        },
        path: ROUTES.contentLibrary,
        component: <WrappedGradeComponent route={ROUTES.contentLibrary} />,
        exact: false,
      },
      {
        appRoles: {
          roles: [],
          schoolRoles: getSchoolRolesSemesterWise([
            SchoolRole.schoolTeacher,
            SchoolRole.schoolStudent,
          ]),
        },
        path: ROUTES.assignments,
        component: <WrappedGradeComponent route={ROUTES.assignments} />,
        exact: false,
      },
      {
        appRoles: {
          roles: getRolesSemesterWise([Role.alwTeacher]),
          schoolRoles: getSchoolRolesSemesterWise([SchoolRole.schoolTeacher]),
        },
        path: ROUTES.teacherExams,
        component: <WrappedGradeComponent route={ROUTES.studentExams} />,
        exact: true,
      },
      {
        appRoles: {
          roles: getRolesSemesterWise([Role.student, Role.alwTeacher]),
          schoolRoles: getSchoolRolesSemesterWise([
            SchoolRole.schoolStudent,
            SchoolRole.schoolTeacher,
          ]),
        },
        path: ROUTES.studentExams,
        component: <WrappedGradeComponent route={ROUTES.studentExams} />,
        exact: false,
      },
      {
        appRoles: {
          roles: getRolesSemesterWise([Role.student, Role.mentor, Role.alwTeacher]),
          schoolRoles: getSchoolRolesSemesterWise([
            SchoolRole.schoolStudent,
            SchoolRole.schoolTeacher,
          ]),
        },
        path: ROUTES.schedule,
        component: <WrappedGradeComponent route={ROUTES.schedule} />,
        exact: true,
      },
      {
        appRoles: {
          roles: getRolesSemesterWise([Role.student]),
          schoolRoles: getSchoolRolesSemesterWise([SchoolRole.schoolStudent]),
        },
        path: ROUTES.studentLiveQuestions,
        component: <WrappedGradeComponent route={ROUTES.studentLiveQuestions} />,
        exact: false,
      },
      {
        appRoles: {
          roles: [Role.student],
          schoolRoles: [SchoolRole.schoolStudent],
        },
        path: ROUTES.units,
        component: <WrappedGradeComponent route={ROUTES.units} />,
        exact: true,
      },
      {
        appRoles: {
          roles: [Role.student],
          schoolRoles: [SchoolRole.schoolStudent],
        },
        path: ROUTES.lesson,
        component: <WrappedGradeComponent route={ROUTES.lesson} />,
        exact: true,
      },
      {
        appRoles: {
          roles: [Role.student],
          schoolRoles: [SchoolRole.schoolStudent],
        },
        path: ROUTES.learningPath,

        component: <WrappedGradeComponent route={ROUTES.learningPath} />,
        exact: true,
      },
      {
        appRoles: {
          roles: getRolesSemesterWise([Role.student]),
        },
        path: ROUTES.promotion,
        component: <Promotion />,
        exact: true,
      },
      {
        appRoles: {
          ...(!DISABLE_B2C_REWARDS && { roles: getRolesSemesterWise([Role.student]) }),
          schoolRoles: getSchoolRolesSemesterWise([SchoolRole.schoolStudent]),
        },
        path: ROUTES.rewards,
        component: <WrappedGradeComponent route={ROUTES.rewards} />,
        exact: false,
      },
      ...(!DISABLE_ARS
        ? [
            {
              appRoles: {
                roles: getRolesSemesterWise([Role.student]),
                schoolRoles: getSchoolRolesSemesterWise([SchoolRole.schoolStudent]),
              },
              path: ROUTES.recommendation,
              component: <WrappedGradeComponent route={ROUTES.recommendation} />,
              exact: false,
            },
          ]
        : []),
      {
        appRoles: {
          roles: getRolesSemesterWise([Role.student]),
          schoolRoles: [SchoolRole.schoolStudent],
        },
        path: ROUTES.selfReport,
        component: <WrappedGradeComponent route={ROUTES.selfReport} />,
        exact: false,
      },
      {
        appRoles: {},
        path: ROUTES.account,
        component: <WrappedGradeComponent route={ROUTES.account} />,
        exact: false,
      },
    ],
  },
];

export const NonGradeOneToThreeRoutes: RoleRouteInfo[] = [
  {
    appRoles: {
      roles: getRolesSemesterWise([Role.student, Role.alwTeacher]),
      schoolRoles: getSchoolRolesSemesterWise([SchoolRole.schoolStudent, SchoolRole.schoolTeacher]),
    },
    path: ROUTES.preparation,
    component: <PreparationMaterial />,
    exact: true,
  },
  {
    appRoles: {
      schoolRoles: [SchoolRole.schoolBranchManager, SchoolRole.schoolAdmin],
    },
    path: ROUTES.adminDashBoard,
    component: <SchoolTeachersList />,
    exact: false,
  },
  {
    appRoles: {
      roles: [Role.contentAuthor],
      adminRoles: [AdminRole.alwAdmin],
    },
    path: ROUTES.nativeEvents,
    component: <NativeEvents />,
    exact: false,
  },
  {
    appRoles: {
      roles: [Role.alwTeacher],
    },
    path: ROUTES.parentQuestions,
    component: <ParentsQuestions />,
    exact: false,
  },
  {
    appRoles: {
      roles: [Role.parent],
    },
    path: ROUTES.childTeacherDetail,
    component: <ChildTeacherDetailQuestion />,
    exact: true,
  },
  {
    appRoles: {
      roles: [Role.parent],
    },
    path: ROUTES.childTeachers,
    component: <ChildTeachers />,
    exact: true,
  },
  {
    appRoles: {
      roles: [Role.parent],
    },
    path: ROUTES.childDashboard,
    component: <ChildDashboard />,
    exact: true,
  },
  {
    appRoles: {
      schoolRoles: [SchoolRole.schoolAdmin, SchoolRole.schoolBranchManager],
    },
    path: ROUTES.alertLogDetails,
    component: <AlertLogsDetails />,
    exact: true,
  },
  {
    appRoles: {
      roles: [Role.alwTeacher],
      schoolRoles: [SchoolRole.schoolBranchManager, SchoolRole.schoolTeacher],
    },
    path: ROUTES.flexibleScheduleWeekly,
    component: <FlexibleWeeklySchedule />,
    exact: true,
  },
  {
    appRoles: {
      schoolRoles: [SchoolRole.schoolBranchManager],
    },
    path: ROUTES.teacherScheduleWeekly,
    component: <FlexibleWeeklySchedule />,
    exact: true,
  },
  {
    appRoles: {
      schoolRoles: [SchoolRole.schoolBranchManager],
    },
    path: ROUTES.teacherScheduleSubjects,
    component: <ScheduleSubjectListing />,
    exact: true,
  },
  {
    appRoles: {
      schoolRoles: [SchoolRole.schoolBranchManager, SchoolRole.schoolTeacher],
    },
    path: ROUTES.flexibleSchedule,
    component: <ScheduleSubjectListing />,
    exact: true,
  },
  {
    appRoles: {
      schoolRoles: [SchoolRole.schoolBranchManager],
    },
    path: ROUTES.teacherScheduleList,
    component: <TeacherScheduleList />,
    exact: true,
  },
  {
    appRoles: {
      schoolRoles: [SchoolRole.schoolAdmin, SchoolRole.schoolBranchManager],
    },
    path: ROUTES.studentClassroomDetails,
    component: <StudentProgressDetails />,
    exact: true,
  },
  {
    appRoles: {
      roles: [Role.alwTeacher],
      schoolRoles: getSchoolRolesSemesterWise([SchoolRole.schoolTeacher]),
    },
    path: ROUTES.semesterSchedule,
    component: <SemesterSchedule />,
    exact: true,
  },
  {
    appRoles: { roles: [Role.alwTeacher], schoolRoles: [SchoolRole.schoolTeacher] },
    path: ROUTES.semesterScheduleDetail,
    component: <SemesterScheduleDetail />,
    exact: true,
  },
  {
    appRoles: { roles: [Role.alwTeacher] },
    path: ROUTES.reports,
    component: <GradesList />,
    exact: true,
  },
  {
    appRoles: { schoolRoles: [SchoolRole.schoolTeacher] },
    path: ROUTES.b2bTeacherClassDetail,
    component: <GradeDetail />,
    exact: true,
  },
  {
    appRoles: { roles: [Role.alwTeacher] },
    path: ROUTES.reportsGradeDetail,
    component: <GradeDetail />,
    exact: true,
  },
  {
    appRoles: { roles: [Role.mentor, Role.alwTeacher] },
    path: ROUTES.eventDetail,
    component: <EventDetail />,
    exact: true,
  },
  {
    appRoles: { schoolRoles: [SchoolRole.schoolBranchManager] },
    path: ROUTES.liveSessionsSchedule,
    component: <LiveSessionsSchedule />,
    exact: true,
  },
  {
    appRoles: { roles: [Role.alwTeacher], schoolRoles: [SchoolRole.schoolTeacher] },
    path: ROUTES.teacherSessionQAs,
    component: <TeacherSessionQAs />,
    exact: true,
  },
  {
    appRoles: { roles: [Role.alwTeacher], schoolRoles: [SchoolRole.schoolTeacher] },
    path: ROUTES.teacherLiveQuestions,
    component: <TeacherLiveSessionQuestions />,
    exact: true,
  },
  {
    appRoles: { schoolRoles: [SchoolRole.schoolBranchManager, SchoolRole.schoolAdmin] },
    path: ROUTES.classroomDetails,
    component: <AdminClassroomDetails />,
    exact: true,
  },
  {
    appRoles: { schoolRoles: [SchoolRole.schoolBranchManager, SchoolRole.schoolAdmin] },
    path: ROUTES.adminReports,
    component: <AdminReports />,
    exact: true,
  },
  {
    appRoles: { schoolRoles: [SchoolRole.schoolBranchManager, SchoolRole.schoolAdmin] },
    path: ROUTES.timetable,
    component: <TimeTable />,
    exact: true,
  },
  {
    appRoles: { schoolRoles: [SchoolRole.schoolBranchManager, SchoolRole.schoolAdmin] },
    path: ROUTES.classTimetable,
    component: <GradeWiseTimeTable />,
    exact: true,
  },
  {
    appRoles: { schoolRoles: [SchoolRole.schoolBranchManager, SchoolRole.schoolAdmin] },
    path: ROUTES.students,
    component: <SchoolStudentDashBoard />,
    exact: true,
  },
  {
    appRoles: { schoolRoles: [SchoolRole.schoolBranchManager, SchoolRole.schoolAdmin] },
    path: ROUTES.teachers,
    component: <SchoolTeacherDashBoard />,
    exact: true,
  },
  {
    appRoles: {},
    path: ROUTES.help,
    component: <HelpLayout />,
    exact: false,
  },
  {
    appRoles: {},
    path: ROUTES.help,
    component: <HelpLayout />,
    exact: false,
  },
  {
    appRoles: {
      roles: [Role.parent],
    },
    path: ROUTES.childrenReports,
    component: <StudentReports />,
    exact: false,
  },
  {
    appRoles: {
      roles: [Role.alwTeacher],
      schoolRoles: [SchoolRole.schoolAdmin, SchoolRole.schoolBranchManager],
    },
    path: ROUTES.adminStudentReport,
    component: <StudentReports />,
    exact: false,
  },
  {
    appRoles: {
      roles: [Role.alwTeacher],
      schoolRoles: [SchoolRole.schoolTeacher],
    },
    path: ROUTES.teacherStudentReport,
    component: <StudentReports />,
    exact: false,
  },
  {
    appRoles: {
      ...(!DISABLE_B2C_GROUP_CHAT && {
        roles: getRolesSemesterWise([Role.student, Role.mentor, Role.alwTeacher]),
      }),
      schoolRoles: getSchoolRolesSemesterWise([SchoolRole.schoolStudent]),
    },
    path: ROUTES.groupChat,
    component: <GroupChat />,
    exact: false,
  },
  {
    appRoles: {
      roles: getRolesSemesterWise([Role.student, Role.alwTeacher]),
      schoolRoles: [SchoolRole.schoolStudent, SchoolRole.schoolTeacher],
    },
    path: ROUTES.questionBankSubjects,
    component: <SubjectsLayout />,
    exact: true,
  },
  {
    appRoles: {
      roles: getRolesSemesterWise([Role.student]),
      schoolRoles: [SchoolRole.schoolStudent],
    },
    path: ROUTES.questionBankQuiz,
    component: <QuestionBankQuiz />,
    exact: false,
  },
  {
    appRoles: {
      roles: getRolesSemesterWise([Role.student, Role.alwTeacher]),
      schoolRoles: getSchoolRolesSemesterWise([SchoolRole.schoolStudent, SchoolRole.schoolTeacher]),
    },
    path: ROUTES.questionBankUnits,
    component: <SubjectsLayout />,
    exact: false,
  },
  {
    appRoles: {
      roles: getRolesSemesterWise([Role.student, Role.alwTeacher]),
      schoolRoles: getSchoolRolesSemesterWise([SchoolRole.schoolStudent, SchoolRole.schoolTeacher]),
    },
    path: ROUTES.questionBankLessons,
    component: <LearningObjectivesLayout />,
    exact: false,
  },
  {
    appRoles: {
      schoolRoles: [SchoolRole.schoolTeacher],
      roles: [Role.alwTeacher],
    },
    path: ROUTES.questionBankQuestionsDetail,
    component: <QuestionsDetailView />,
    exact: false,
  },
  {
    appRoles: {
      schoolRoles: [SchoolRole.schoolTeacher],
      roles: [Role.alwTeacher],
    },
    path: ROUTES.questionBankCreateNewQuestion,
    component: <CreateNewQuestion />,
    exact: false,
  },
  {
    appRoles: {
      roles: [Role.student],
      schoolRoles: [SchoolRole.schoolStudent],
    },
    path: ROUTES.quizExam,
    component: <Reinforcements />,
    exact: false,
  },
  {
    appRoles: {
      roles: getRolesSemesterWise([Role.student]),
      schoolRoles: getSchoolRolesSemesterWise([SchoolRole.schoolStudent]),
    },
    path: ROUTES.exercises,
    component: <Exercises />,
    exact: false,
  },
  {
    appRoles: {
      roles: [Role.student, Role.contentAuthor],
      schoolRoles: [SchoolRole.schoolStudent],
    },
    path: ROUTES.qiyas,
    component: <Qiyas />,
    exact: false,
  },
  ...(!DISABLE_B2B_GRADING
    ? [
        {
          appRoles: {
            schoolRoles: [
              SchoolRole.schoolAdmin,
              SchoolRole.schoolTeacher,
              SchoolRole.schoolBranchManager,
            ],
          },
          path: ROUTES.gradeMapping,
          component: <GradeMapping />,
          exact: true,
        },
        {
          appRoles: {
            schoolRoles: [
              SchoolRole.schoolAdmin,
              SchoolRole.schoolTeacher,
              SchoolRole.schoolBranchManager,
            ],
          },
          path: ROUTES.gradeReport,
          component: <GradeReports />,
          exact: true,
        },
      ]
    : []),
];
