import React, { createElement } from 'react';
import cn from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { Icon, IconCommon } from 'lib';
import { ROUTES } from 'constants/routes';

import { MenusType } from 'components/LayoutBase/components/Sidebar/types';
import styles from './Sidebar.module.scss';

export function Menu({ id, path, label, icon, image, disabled, setIsOpen, setElement }: MenusType) {
  return (
    <NavLink
      to={path}
      key={id || label}
      id={id}
      activeClassName={styles.active}
      exact={path === ROUTES.root}
      className={cn(styles.navigation__item, {
        [styles.disabled]: disabled,
      })}
      ref={(el) => {
        setElement(el);
      }}
      onClick={() => setIsOpen()}
    >
      {image ? (
        <img src={image} alt="icon" className={styles.navigation__icon} />
      ) : (
        <Icon className={styles.navigation__icon} title={label} type={icon ?? 'info'} size="s24" />
      )}
      <div className={styles.navigation__title}>{label}</div>

      {disabled && <IconCommon className="lock" materialIconType="outlined" isMaterialIcon />}
    </NavLink>
  );
}
